<template>
  <div class="view-turntable">
    <div class="titlebox">
      <div class="changetype" @click="toupdata">
        <img src="../../assets/images/kaijiang/checkicon.png" alt="" />
        <span v-show="!islucky">上期欧皇</span>
        <span v-show="islucky">本期转盘</span>
      </div>
      <div class="text">福利转盘</div>
      <div
        class="tipsbox"
        @click="
          () => {
            this.showtips = !this.showtips;
          }
        "
      >
        <img src="../../assets/images/kaijiang/tipsicon.png" alt="" />
        玩法说明
      </div>
    </div>

    <div class="turntable-box">
      <div
        class="turntable-row-box"
        v-for="(item, index) in joinList"
        :key="index"
        :class="[`flex${index}`]"
      >
        <div
          class="turntable-row-item"
          v-for="vItem in item"
          :key="vItem"
          :class="[(vItem * 1 + 1) % 2 == 0 ? 'two' : 'one']"
        >
          <div
            class="jiao"
            :class="{ 'official-row-item': officialRatioList.includes(vItem) }"
          ></div>
          <img
            :src="list28[vItem]?.avatar ? list28[vItem].avatar : noimg"
            alt=""
          />
          <div class="float-turntable-row-item"></div>
        </div>
      </div>

      <!-- <div class="no">{{ date_no }}</div> -->
      <div class="centerbox">
        <div class="topbg">
          <img
            class="titleimg"
            src="../../assets/images/kaijiang/m-kjtitle.png"
            alt=""
          />
          <div class="datano">{{ date_no }}</div>
          <div class="timebox" v-if="!islucky">
            <div class="timetext">开奖时间:</div>
            <el-statistic
              class="timenum"
              format="DD天HH小时mm分钟ss秒"
              :value="drawTime"
              time-indices
            >
            </el-statistic>
          </div>
          <div class="timebox" v-else>
            <div class="endtime">开奖结果已公布</div>
          </div>
          <div class="pricebox" v-if="!islucky">
            <div class="onetitle">本期福利</div>
            <div><Cprice :price="bean" :size="0.2" /></div>
            <div v-if="user.anchor == 0">
              <div class="twotitle">本期预计获得奖励</div>
              <div v-if="this.joinstate">
                <Cprice :price="award_bean" :size="0.12" />
              </div>
              <div v-else>还未参加</div>
            </div>
          </div>
          <div class="pricebox2" v-else>
            <div>
              <div class="onetitle">上期福利</div>
              <div><Cprice :price="bean" :size="0.2" /></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="btnbox">
      <div class="btncenter">
        <div class="allbtn">
          <div v-if="!islucky">
            <div v-if="!this.joinstate">
              <div class="joinbox j11" v-if="user.anchor == 0" @click="tojoin">
                <div
                  style="
                    width: 100%;
                    text-align: center;
                    height: 0.1rem;
                    line-height: 0.3rem;
                  "
                >
                  立即参与
                </div>
                <span class="usertext">参与本期需要{{ userpoint }}积分</span>
              </div>
              <div class="joinbox j11" v-if="user.anchor == 1" @click="tojoin">
                立即参与
              </div>
            </div>
            <div v-else>
              <div class="joinbox j2">已参与</div>
            </div>
          </div>
          <div
            class="twobtnbox"
            v-else
            :style="{
              'justify-content':
                showlist == false ? ' center' : 'space-between',
            }"
          >
            <div v-if="this.luckyjoinstate">
              <div
                class="joinbox2 j1"
                @click="showluckybean"
                v-if="user.inviter_id == luckyid"
              >
                已中奖
              </div>
              <div class="joinbox2 j3" v-else-if="luckyid == 0">
                <div
                  style="
                    width: 100%;
                    text-align: center;
                    height: 0.1rem;
                    line-height: 0.3rem;
                  "
                >
                  官方中奖
                </div>
                <span class="usertext2">奖池奖金将加入下期奖金</span>
              </div>
              <div class="joinbox2 j3" v-else>未中奖</div>
            </div>
            <div v-else>
              <div class="joinbox2 j3">未参与</div>
            </div>
            <div
              class="joinbox2 j1"
              style="
                display: flex;
                align-items: center;
                justify-content: center;
                padding-bottom: 0.12rem;
              "
              @click="replay"
              v-if="islucky && showlist != false"
            >
              <img
                src="../../assets/images/kaijiang/replay.png"
                alt=""
                style="width: 0.22rem; margin-right: 0.05rem"
              />
              重播动画
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="turntable-btns"></div>
    <div class="listtitle">上榜主播排名</div>
    <div class="listbox">
      <div class="refsbox" v-show="!islucky">
        <div class="refs point" @click="updata">
          <img
            src="../../assets/images/kaijiang/reficon.png"
            :class="restStatus ? 'restAni' : ''"
            alt=""
          />
          <div class="reftext">获取最新数据</div>
        </div>
      </div>
      <div class="refsbox" v-show="islucky"></div>
      <div class="toplist">
        <div class="toplistitem" v-for="(item, index) in 6" :key="item">
          <!-- 显示每个盒子的相应项目 -->
          <div class="items">
            <div class="itemstitle" :class="`t${item}`"></div>
            <div class="userbox">
              <img :src="toplist[index]?.user.avatar || noimg" alt="" />
            </div>
            <div class="username">
              {{ toplist[index]?.user.name || "虚位以待" }}
            </div>
            <div class="usernum" v-if="toplist[index]?.users_num">
              {{ toplist[index]?.users_num }}人
            </div>
          </div>
        </div>
      </div>
      <div class="elselist">
        <div class="elsetitle">
          <div class="lline"></div>
          <div class="titletext">其他主播排名</div>
          <div class="rline"></div>
        </div>
        <div class="elselistbox" v-if="elselist.length > 0">
          <div
            class="elseitem"
            v-for="(item, index) in elselist"
            :key="item.id"
          >
            <div class="numbox">
              {{ index + 7 }}
            </div>
            <div class="userimgbox">
              <img :src="item.user.avatar" alt="" />
            </div>
            <div class="ename">{{ item.user.name }}</div>
            <div class="userjoinnum">{{ item.users_num }}人</div>
          </div>
        </div>
        <div class="nulldata" v-else>
          <div class="imgbox">
            <img src="@/assets/images/newuser/mb-table-nulldata.png" alt="" />
            <div class="nulltext">暂无内容</div>
          </div>
        </div>
      </div>
    </div>
    <van-overlay
      :lock-scroll="false"
      @mousewheel.prevent.native="() => {}"
      :show="windshow"
    >
      <div class="wintips" v-if="windshow">
        <div class="content">
          <div class="windhead"></div>
          <div class="wincontent">
            <div><Cprice :price="getluckybean" :size="0.2" /></div>
            <img
              class="contentimg"
              src="../../assets/images/kaijiang/wincontent.png"
              alt=""
            />
          </div>
          <div class="winenter">
            <div class="winbtn point" @click="windshow = !windshow">确认</div>
          </div>
        </div>
      </div>
    </van-overlay>
    <van-overlay
      :lock-scroll="false"
      @mousewheel.prevent.native="() => {}"
      :show="showtips"
    >
      <div class="showtips" v-if="showtips">
        <div class="tipscontentbox">
          <div class="titlebox">
            <p>玩法说明</p>
            <img
              class="closeicon point"
              src="../../assets/images/new/bg7.png"
              alt=""
              @click="
                () => {
                  this.showtips = !this.showtips;
                }
              "
            />
          </div>
          <div class="line"></div>
          <div class="contenttext" v-html="data_text"></div>
          <div class="enterbtnbox">
            <div
              class="btn point"
              @click="
                () => {
                  this.showtips = !this.showtips;
                }
              "
            >
              确定
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import Cprice from "../../components/common/Cprice.vue";
import {
  getturntabledetailApi,
  jointurntableApi,
  up_awardApi,
  getturntableApi,
} from "../../network/api";
import { mapState } from "vuex";
export default {
  components: { Cprice },
  data() {
    return {
      joinList: [
        [0, 1, 2, 3, 4],
        [5, 6, 7, 8, 9, 10, 11, 12, 13],
        [14, 15, 16, 17, 18],
        [19, 20, 21, 22, 23, 24, 25, 26, 27],
      ],
      activeIndex: 0,
      officialRatioList: [1, 4, 9, 14, 18, 22, 25],
      mockData: {
        official_ratio: "25",
        list: [],
      },

      luckindex: null,

      foritemindex: null,
      drawTime: null,
      countdownInterval: null,

      listdata: {}, //储存箱子信息
      // 上期信息
      luckyid: null,
      islucky: false,

      bean: "",
      userpoint: "",
      joinstate: null,
      luckyjoinstate: null,
      getluckybean: "",

      windshow: false,

      date_no: "", //转盘期号
      data_id: "", //转盘id
      data_text: "", //转盘玩法说明
      isplay: false,
      clicktype: true, //防止点击过快

      toplist: [], //前六主播
      elselist: [], //其余主播

      noimg: require("../../assets/images/kaijiang/nopeople.png"),

      showtips: false,
      award_bean: "",
      restStatus: false,

      showlist: null, //如果上期没数据就是这个判断
      statustype: null, // 判断是否开奖
      Timer: null,
    };
  },
  created() {
    this.getdetaildata();
  },
  mounted() {
    this.foritem();
    this.countdownInterval = setInterval(this.updateCountdown, 1000);
  },
  beforeDestroy() {
    clearInterval(this.countdownInterval);
  },
  methods: {
    updata() {
      this.restStatus = true;
      this.getdetaildata();
      setTimeout(() => {
        this.restStatus = false;
      }, 1000);
    },
    totop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // 平滑滚动
      });
    },
    //展示中奖弹窗
    showluckybean() {
      this.windshow = true;
    },
    replay() {
      if (this.clicktype) {
        this.totop();
        this.clicktype = false;
        clearInterval(this.foritemindex);
        this.toopen(this.luckyid);
      } else {
        this.$message.error("你点的太快了");
      }
    },

    // 获得上期数据
    async toupdata() {
      if (!this.isplay) {
        this.islucky = !this.islucky;
        const list = document.querySelectorAll(".float-turntable-row-item");
        for (let i = 0; i < list.length; i++) {
          if (list[i].style.opacity == 1) {
            list[i].style.opacity = 0;
          }
        }
        // 切换到上期福利
        if (this.islucky) {
          clearInterval(this.foritemindex);
          clearInterval(this.countdownInterval);
          const res = await up_awardApi();
          if (res.data.code == 200) {
            if (res.data.data.data != false) {
              this.mockData.list = res.data.data.top_list;
              this.elselist = res.data.data.residue_list;
              this.toplist = res.data.data.top_list;
              this.luckyid = res.data.data.data.pid;
              this.bean = res.data.data.data.total_bean;
              this.luckyjoinstate = res.data.data.is_join;
              this.getluckybean = res.data.data.data.bean;
              this.date_no = res.data.data.data.date_no;
            } else {
              this.showlist = res.data.data.data;
            }
          } else {
            this.$message.error(res.data.message);
          }
        } else {
          this.countdownInterval = setInterval(this.updateCountdown, 1000);
          this.getdetaildata();
          this.foritem();
        }
      } else {
        this.$message.error("动画正在播放");
      }
    },

    async tojoin() {
      if (this.clicktype) {
        this.clicktype = false;
        setTimeout(() => {
          this.clicktype = true;
        }, 4000);
        let params = {
          id: this.data_id,
        };
        const res = await jointurntableApi(params);
        if (res.data.code == 200) {
          this.getdetaildata();
          this.$message.success(res.data.message);
        } else {
          this.$message.error(res.data.message);
        }
      } else {
        this.$message.error("你点的太快了");
      }
    },
    // 获得本期的数据
    async getdetaildata() {
      const reslistdata = await getturntableApi();
      this.listdata = reslistdata.data.data;
      this.userpoint = this.listdata.score;
      this.bean = this.listdata.bean;
      this.date_no = this.listdata.date_no;
      this.data_id = this.listdata.id;
      this.data_text = this.listdata.description;
      this.statustype = this.listdata.status;
      this.drawTime = new Date(
        this.listdata.end_time.replace(/\-/g, "/")
      ).getTime();
      if (this.statustype == 0) {
        let params = {
          date_no: this.date_no,
        };
        const res = await getturntabledetailApi(params);
        if (res.data.code == 200) {
          this.joinstate = res.data.data.is_join;
          this.mockData.list = res.data.data.top_list;
          this.toplist = res.data.data.top_list;
          this.elselist = res.data.data.residue_list;
          this.award_bean = res.data.data.award_bean;
        } else {
          this.$message.error(res.data.message);
        }
      } else {
        // 如果本期没有数据
        clearInterval(this.foritemindex);
        clearInterval(this.countdownInterval);
        this.islucky = true;
        const res = await up_awardApi();
        if (res.data.code == 200) {
          this.mockData.list = res.data.data.top_list;
          this.elselist = res.data.data.residue_list;
          this.toplist = res.data.data.top_list;
          this.luckyid = res.data.data.data.pid;
          this.bean = res.data.data.data.total_bean;
          this.luckyjoinstate = res.data.data.is_join;
          this.getluckybean = res.data.data.data.bean;
          this.date_no = res.data.data.data.date_no;
        } else {
          this.$message.error(res.data.message);
        }
        this.$message.error("上期已结束，下期活动请等待通知");
      }
    },
    // 轮询倒计时
    updateCountdown() {
      const now = new Date().getTime();
      const timeRemaining = this.drawTime - now;
      if (this.statustype == 0) {
        // 倒计时结束调用开奖接口
        if (timeRemaining < 0) {
          setTimeout(() => {
            this.getluckydata();
          }, 1000);
          clearInterval(this.countdownInterval);
        }
      }
    },
    // 获取中奖玩家
    async getluckydata() {
      const res = await up_awardApi();
      if (res.data.data.data == false) {
        this.getluckydata();
      } else {
        this.luckyid = res.data.data.data.pid;
        this.toopen(this.luckyid);
      }
    },
    toopen(v) {
      clearInterval(this.foritemindex);
      this.luckindex = this.getFirstIndexForUser(v);
      this.activeIndex = 0;
      const list = document.querySelectorAll(".float-turntable-row-item");
      for (let i = 0; i < list.length; i++) {
        if (list[i].style.opacity == 1) {
          list[i].style.opacity = 0;
        }
      }
      this.init();
    },
    // 找到中奖的下标
    getFirstIndexForUser(userId) {
      // 存储所有符合条件的下标
      const matchingIndices = [];
      // 遍历 list28
      for (let i = 0; i < this.list28.length; i++) {
        const item = this.list28[i];
        // 检查当前元素是否符合条件
        if (item) {
          if (item.id == userId) {
            // 将符合条件的下标存储到数组中
            matchingIndices.push(i);
          }
        }
      }
      // 如果找到了符合条件的下标
      if (matchingIndices.length > 0) {
        // 随机选择一个下标
        // const randomIndex = Math.floor(Math.random() * matchingIndices.length);
        return matchingIndices[1];
      } else {
        return matchingIndices[0];
      }
    },
    init() {
      this.isplay = true;
      const list = document.querySelectorAll(".float-turntable-row-item");
      const initialIntervalTime = 70; // 初始转动速度（时间间隔）
      const finalIntervalTime = 200; // 最终转动速度（时间间隔）
      const fadeTime = 100; // 淡出时间
      const totalItems = list.length; // 获取项目总数

      // 计算从当前 activeIndex 到中奖索引 luckindex 所需的步数
      const stepsToLuckIndex =
        (this.luckindex - this.activeIndex + totalItems) % totalItems;

      // 设定转多少圈
      const circlesToSpin = 2; // 可以根据需求调整这个值
      const totalSteps = circlesToSpin * totalItems + stepsToLuckIndex;

      const decelerationStart = 5; // 从倒数第10步开始减速
      let currentStep = 0; // 当前步数
      let currentIntervalTime = initialIntervalTime; // 当前时间间隔

      // 渲染每一帧
      const renderFrame = () => {
        // 设置当前项的透明度
        let num = this.activeIndex;
        list[num].style.opacity = 1;
        setTimeout(() => {
          list[num].style.opacity = 0;
        }, fadeTime);

        // 更新 activeIndex
        if (this.activeIndex < totalItems - 1) {
          this.activeIndex += 1;
        } else {
          this.activeIndex = 0;
        }

        // 逐渐增加时间间隔以减慢转动速度
        if (currentStep >= totalSteps - decelerationStart) {
          // 开始减速
          const stepsRemaining = totalSteps - currentStep;
          const stepProgress =
            (decelerationStart - stepsRemaining) / decelerationStart;
          currentIntervalTime = Math.max(
            initialIntervalTime +
              (finalIntervalTime - initialIntervalTime) * stepProgress,
            finalIntervalTime
          );
        }

        currentStep += 1;

        // 检查是否到达目标步数
        if (currentStep >= totalSteps) {
          // 停止动画并显示中奖项
          list[this.luckindex].style.opacity = 1;
          this.activeIndex = 0;
          this.isplay = false;
          this.clicktype = true;
          if (!this.islucky) {
            setTimeout(() => {
              this.getdetaildata();
              this.foritem();
            }, 1000);
          }
        } else {
          // 使用 setTimeout 进行下一帧渲染
          setTimeout(() => {
            requestAnimationFrame(renderFrame);
          }, currentIntervalTime);
        }
      };

      // 开始动画
      requestAnimationFrame(renderFrame);
    },
    //进入随机旋转
    foritem() {
      const list = document.querySelectorAll(".float-turntable-row-item");
      this.foritemindex = setInterval(() => {
        let num = this.activeIndex;
        list[num].style.opacity = 1;
        setTimeout(() => {
          list[num].style.opacity = 0;
        }, 200);
        if (this.activeIndex < 27) {
          this.activeIndex += 1;
        } else {
          this.activeIndex = 0;
        }
      }, 200);
    },
  },
  computed: {
    ...mapState(["user"]),
    list28() {
      const resultList = new Array(28).fill(null);
      const officialUser = {
        id: 0,
        name: "官方",
        avatar:
          "https://api.tmskins.com/uploads/images/c712335ec0ba72b953ab25795e60383b.jpg",
      };
      // 填充官方用户
      this.officialRatioList.forEach((index) => {
        resultList[index] = officialUser;
      });

      let filledSlots = 0;

      // // 处理比例为0的用户
      // const zeroRatioUsers = this.mockData.list.filter(
      //   (item) => item.ratio == 0
      // );
      // if (zeroRatioUsers.length > 0) {
      //   zeroRatioUsers.forEach((item) => {
      //     if (filledSlots < 28) {
      //       const availableIndex = resultList.findIndex(
      //         (slot) => slot === null
      //       );
      //       if (availableIndex !== -1) {
      //         resultList[availableIndex] = nullUser;
      //         filledSlots++;
      //       }
      //     }
      //   });
      // }

      // 处理比例大于0的用户
      this.mockData.list.forEach((item) => {
        if (item.ratio > 0) {
          const num = Math.round((item.ratio / 100) * 28);
          for (let i = 0; i < num && filledSlots < 28; i++) {
            const availableIndex = resultList.findIndex((slot) => slot == null);
            if (availableIndex !== -1) {
              resultList[availableIndex] = item.user;
              filledSlots++;
            }
          }
        }
      });

      return resultList;
    },
  },
};
</script>

<style lang="scss">
.view-turntable {
  width: 100%;
  height: 100%;
  background: rgba(7, 7, 7, 0.7);
  .titlebox {
    width: 100%;
    height: 0.44rem;
    background: #020715;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 0.12rem;
    .changetype {
      background: url("../../assets/images/kaijiang/checkbox.png") no-repeat;
      background-size: 100% 100%;
      width: 0.78rem;
      height: 0.26rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 500;
      font-size: 0.12rem;
      color: #c5c0ff;
      padding: 0 0.05rem;
      img {
        width: 0.14rem;
      }
    }
    .tipsbox {
      width: 0.98rem;
      height: 0.28rem;
      background: url("../../assets/images/kaijiang/tipsbox.png") no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 0.12rem;
      padding: 0 0.07rem;
      img {
        width: 0.14rem;
        margin-right: 0.04rem;
      }
    }
    .text {
      font-weight: 500;
      font-size: 0.16rem;
      color: #ffffff;
    }
  }
  .turntable-box {
    width: 3.45rem;
    height: 5.8rem;
    display: flex;
    position: relative;
    margin: 0 auto;
    border: 0.06rem solid #302764;
    border-radius: 0.04rem;
    // background: url("../../assets/images/kaijiang/kjzpbg.png") no-repeat;
    background-size: 100% 100%;
    z-index: 1;
    // flex-wrap: wrap;
    .centerbox {
      width: 2.07rem;
      height: 4.28rem;
      position: absolute;
      top: 12.5%;
      left: 19.5%;
      background: url("../../assets/images/kaijiang/m-kjcenterbg.png") no-repeat;
      background-size: 100% 100%;
      .topbg {
        width: 100%;
        img {
          width: 1.99rem;
          margin: 0 auto;
        }
        .datano {
          width: 1.3rem;
          height: 0.28rem;
          background: url("../../assets/images/kaijiang/m-kjdatano.png")
            no-repeat;
          background-size: 100% 100%;
          margin: 0 auto;
          font-weight: 500;
          font-size: 0.12rem;
          color: #c5c0ff;
          text-align: center;
          line-height: 0.28rem;
        }
        .timebox {
          width: 2.07rem;
          height: 0.26rem;
          background: linear-gradient(
            90deg,
            rgba(143, 133, 211, 0) 0%,
            #5f588f 50%,
            rgba(143, 133, 211, 0) 100%
          );
          margin-top: 0.2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.1rem;
          .timetext {
            font-weight: 400;
            font-size: 0.1rem;
            color: #ffffff;
          }
          .timenum {
            width: unset;
          }
          .timenum .con .number {
            width: unset;
            color: #fff !important;
            font-weight: 500 !important;
            font-size: 0.12rem !important;
            line-height: 0.24rem !important;
            height: 0.24rem !important;
          }
          .endtime {
            color: #fff !important;
            font-weight: 500 !important;
            font-size: 0.12rem !important;
            line-height: 0.24rem !important;
            height: 0.24rem !important;
            padding: 0 0.04rem;
          }
        }
        .pricebox {
          width: 1.89rem;
          height: 1.24rem;
          background: url("../../assets/images/kaijiang/m-pricebox.png")
            no-repeat;
          background-size: 100% 100%;
          margin: 0.12rem auto;
          text-align: center;
          padding: 0.15rem;
          div {
            margin-bottom: 0.05rem;
          }
          .twotitle {
            font-weight: 400;
            font-size: 0.12rem;
            color: #ffffff;
            line-height: 0.12rem;
          }
        }
        .pricebox2 {
          width: 1.89rem;
          height: 1.24rem;
          background: url("../../assets/images/kaijiang/m-pricebox.png")
            no-repeat;
          background-size: 100% 100%;
          margin: 0.12rem auto;
          text-align: center;
          padding: 0.15rem;
          display: flex;
          justify-content: center;
          align-items: center;
          div {
            margin-bottom: 0.05rem;
          }
        }
      }
    }

    .no {
      position: absolute;
      right: 0.2rem;
      top: 0.6rem;
      width: 2.7rem;
      height: 0.5rem;
      //   background: url("../../assets/images/kaijiang/qhbg.png") no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: rotate(5deg);
      font-weight: 500;
      font-size: 0.24rem;
      color: #c5c0ff;
      padding-bottom: 0.1rem;
      padding-right: 0.1rem;
    }
    .turntable-row-box {
      display: flex;

      .turntable-row-item {
        width: 0.51rem;
        height: 0.52rem;
        // background: pink;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0.015rem;
        img {
          width: 0.32rem;
          height: 0.32rem;
          border-radius: 50%;
        }
        .float-turntable-row-item {
          width: 0.7rem;
          height: 0.7rem;
          position: absolute;
          background: url("../../assets/images/kaijiang/acitem.png") no-repeat;
          background-size: 100% 100%;
          opacity: 0;
          z-index: 1;
        }
      }
      .jiao {
        position: absolute;
        width: 0.27rem;
        height: 0.27rem;
        top: 0;
        left: 0;
      }
      .official-row-item {
        background: url("../../assets/images/kaijiang/guanfanglogo.png")
          no-repeat;
        background-size: 100% 100%;
      }
    }
    .turntable-row-box:nth-of-type(1) {
      display: flex;
      position: absolute;
      left: 0.05rem;
      top: 0.1rem;
    }
    .turntable-row-box:nth-of-type(2) {
      display: flex;
      flex-direction: column;
      position: absolute;
      right: 0.05rem;
      top: 0.1rem;
    }
    .turntable-row-box:nth-of-type(3) {
      display: flex;
      position: absolute;
      flex-direction: row-reverse;
      right: 0.05rem;
      bottom: 0.1rem;
    }
    .turntable-row-box:nth-of-type(4) {
      display: flex;
      flex-direction: column-reverse;
      position: absolute;
      left: 0.05rem;
      bottom: 0.1rem;
    }
  }
  .btnbox {
    width: 100%;
    height: 1rem;
    position: absolute;
    top: 7rem;
    .btncenter {
      display: flex;
      justify-content: center;
      .allbtn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .joinbox {
          width: 2.06rem;
          height: 0.58rem;
          text-align: center;
          font-family: Microsoft YaHei UI, Microsoft YaHei UI;
          font-weight: bold;
          font-size: 0.22rem;
          color: #ff7a00;
          line-height: 0.45rem;
          //   -webkit-text-stroke: 0.01rem #ff7a00;
        }

        .usertext {
          font-family: PingFang SC, PingFang SC;
          display: block;
          font-weight: 500;
          font-size: 0.1rem;
          color: #ff7a00;
          height: 0.25rem;

          -webkit-text-stroke: unset;
        }
        .usertext2 {
          font-family: PingFang SC, PingFang SC;
          display: block;
          font-weight: 500;
          font-size: 0.1rem;
          color: #868686 !important;
          height: 0.25rem;
          -webkit-text-stroke: unset;
        }
      }
      .twobtnbox {
        width: 3.16rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .joinbox2 {
          width: 1.46rem;
          height: 0.58rem;
          text-align: center;
          font-family: Microsoft YaHei UI, Microsoft YaHei UI;
          font-weight: bold;
          font-size: 0.22rem;
          color: #ff7a00;
          line-height: 0.45rem;
          //   -webkit-text-stroke: 0.01rem #ff7a00;
        }
      }
      .j1 {
        background: url("../../assets/images/kaijiang/j1.png") no-repeat;
        background-size: 100% 100%;
      }
      .j11 {
        background: url("../../assets/images/kaijiang/j11.png") no-repeat;
        background-size: 100% 100%;
      }
      .j2 {
        background: url("../../assets/images/kaijiang/j2.png") no-repeat;
        background-size: 100% 100%;
        color: #ff9d43 !important;
      }
      .j3 {
        background: url("../../assets/images/kaijiang/j3.png") no-repeat;
        background-size: 100% 100%;
        color: #868686 !important;
      }
    }
  }
  .turntable-btns {
    width: 100%;
    margin-top: 1rem;
    display: flex;
    justify-content: center;
  }
  .listtitle {
    position: absolute;
    top: 8rem;
    right: 4%;
    width: 3.5rem;
    height: 0.35rem;
    margin: 0 auto;
    font-family: Microsoft YaHei UI, Microsoft YaHei UI;
    font-weight: bold;
    font-size: 0.14rem;
    color: #ffffff;
    line-height: 0.3rem;
    text-shadow: 0 0.01rem 0.01rem rgba(0, 0, 0, 0.5);
    text-align: center;
    background: url("../../assets/images/kaijiang/listtitle.png") no-repeat;
    background-size: 100% 100%;
  }
  .listbox {
    width: 3.5rem;
    min-height: 10.1rem;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    .refsbox {
      width: 100%;
      height: 0.4rem;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin: 0.12rem 0;
      padding: 0 0.1rem;
      position: absolute;
      right: 0;
      top: 0.4rem;
      .refs {
        display: flex;
        align-items: center;
        img {
          width: 0.12rem;
          margin-right: 0.04rem;
        }
        .restAni {
          animation: rotate 0.5s forwards;
        }
        .reftext {
          font-weight: 500;
          font-size: 0.12rem;
          color: #c5c0ff;
        }
      }
    }
    .toplist {
      width: 3.5rem;
      height: 2.9rem;
      margin: 0.9rem auto 0.4rem;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .toplistitem {
        width: 1.1rem;
        height: 1.3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        .items {
          width: 1rem;
          height: 1.2rem;
          position: relative;
          background: url("../../assets/images/kaijiang/toplistitem.png")
            no-repeat;
          background-size: 100% 100%;
          .itemstitle {
            width: 0.62rem;
            height: 0.2rem;
            position: absolute;
            top: -0.05rem;
            right: 19%;
          }
          .t1 {
            background: url("../../assets/images/kaijiang/t1.png") no-repeat;
            background-size: 100% 100%;
          }
          .t2 {
            background: url("../../assets/images/kaijiang/t2.png") no-repeat;
            background-size: 100% 100%;
          }
          .t3 {
            background: url("../../assets/images/kaijiang/t3.png") no-repeat;
            background-size: 100% 100%;
          }
          .t4 {
            background: url("../../assets/images/kaijiang/t4.png") no-repeat;
            background-size: 100% 100%;
          }
          .t5 {
            background: url("../../assets/images/kaijiang/t5.png") no-repeat;
            background-size: 100% 100%;
          }
          .t6 {
            background: url("../../assets/images/kaijiang/t6.png") no-repeat;
            background-size: 100% 100%;
          }
          .userbox {
            width: 100%;
            height: 0.65rem;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 0.1rem;
            img {
              width: 0.42rem;
              height: 0.42rem;
              border-radius: 50%;
              background: #ae9fc5;
              border-radius: 50%;
              padding: 0.02rem;
            }
          }
          .username {
            width: 70%;
            height: 0.2rem;
            line-height: 0.2rem;
            font-weight: 400;
            font-size: 0.12rem;
            color: #ffffff;
            margin: 0 auto;
            text-align: center;
            border: 0.01rem solid transparent;
            border-image: linear-gradient(
                90deg,
                rgba(255, 255, 255, 0),
                rgba(255, 255, 255, 1),
                rgba(255, 255, 255, 0)
              )
              1 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .usernum {
            width: 100%;
            text-align: center;
            font-weight: 400;
            font-size: 0.12rem;
            color: #ffffff;
            line-height: 0.2rem;
          }
        }
      }
    }
    .elselist {
      width: 3.5rem;
      margin: 0 auto;
      .elsetitle {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .lline {
          width: 1.24rem;
          height: 0;
          border: 0.01rem solid;
          border-image: linear-gradient(
              256deg,
              rgba(144, 142, 193, 1),
              rgba(144, 142, 193, 0)
            )
            2 2;
          opacity: 0.8;
        }
        .rline {
          width: 1.24rem;
          height: 0;
          border: 0.01rem solid;
          border-image: linear-gradient(
              90deg,
              rgba(144, 142, 193, 1),
              rgba(144, 142, 193, 0)
            )
            2 2;
          opacity: 0.8;
        }
        .titletext {
          font-weight: 400;
          font-size: 0.14rem;
          color: #ffffff;
        }
      }
      .elselistbox {
        width: 100%;
        height: 5rem;
        overflow-y: scroll;
        margin-top: 0.4rem;
        .elseitem {
          width: 3.5rem;
          height: 0.44rem;
          margin-bottom: 0.12rem;
          background: #211c2c;
          border-radius: 0.02rem;
          border: 0.01rem solid #756296;
          display: flex;
          align-items: center;
          .numbox {
            width: 0.32rem;
            text-align: center;
            line-height: 0.44rem;
            font-weight: 400;
            font-size: 0.14rem;
            color: #ffffff;
          }
          .userimgbox {
            width: 0.6rem;
            height: 0.44rem;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 0.32rem;
              height: 0.32rem;
              border-radius: 50%;
            }
          }
          .ename {
            width: 1.9rem;
            font-weight: 400;
            font-size: 0.14rem;
            color: #ffffff;
            margin-left: 0.12rem;
          }
          .userjoinnum {
            font-weight: 400;
            font-size: 0.14rem;
            color: #ffffff;
          }
        }
        .e1 {
          background: #211c2c;
        }
        .e2 {
          background: #020315;
        }
      }
      /* 对于 WebKit 浏览器（如 Chrome 和 Safari） */
      .elselistbox::-webkit-scrollbar {
        display: none; /* 隐藏滚动条 */
      }

      /* 对于 Firefox 浏览器 */
      .elselistbox {
        scrollbar-width: none; /* 隐藏滚动条 */
      }

      /* 对于 IE 和 Edge 浏览器 */
      .elselistbox {
        -ms-overflow-style: none; /* 隐藏滚动条 */
      }
      .nulldata {
        width: 100%;
        min-height: 2rem;
        max-height: 5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        .imgbox {
          width: 0.8rem;
          height: 0.8rem;
          img {
            width: 100%;
          }
          .nulltext {
            text-align: center;
            font-size: 0.12rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #c5bbed;
          }
        }
      }
    }
  }

  .wintips {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 395;
    display: flex;
    justify-content: center;
    align-items: center;
    .content {
      width: 3.5rem;
      .windhead {
        width: 100%;
        height: 0.32rem;
        background: url("../../assets/images/kaijiang/wintitle.png") no-repeat;
        background-size: 100% 100%;
        margin-bottom: 0.1rem;
      }
      .wincontent {
        width: 100%;
        height: 2.5rem;

        .contentimg {
          height: 2.5rem;
          margin: 0 auto;
        }
      }
      .winenter {
        width: 100%;
        height: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        .winbtn {
          width: 1.63rem;
          height: 0.4rem;
          background: url("../../assets/images/kaijiang/winenter.png") no-repeat;
          background-size: 100% 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 0.16rem;
          color: #9c5800;
        }
      }
    }
  }
  .showtips {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 395;
    display: flex;
    justify-content: center;
    align-items: center;
    .tipscontentbox {
      width: 3.35rem;
      background: #08081e;
      border-radius: 0.04rem;
      border: 0.02rem solid #5d5d9b;
      .titlebox {
        width: 100%;
        height: 0.38rem;
        text-align: center;
        line-height: 0.38rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 0.16rem;
        color: #dfdbff;
        position: relative;
        display: flex;
        justify-content: center;
        background: #08081e;
        img {
          position: absolute;
          right: 0.2rem;
          top: 0.1rem;
          width: 0.12rem;
        }
      }
      .line {
        width: 100%;
        height: 0;
        border: 0.01rem solid;
        border-image: linear-gradient(
            90deg,
            rgba(93, 93, 155, 0),
            rgba(93, 93, 155, 1),
            rgba(93, 93, 155, 0)
          )
          1 1;
      }
      .contenttext {
        width: 2.95rem;
        margin: 0.12rem auto;
        font-weight: 400;
        font-size: 0.12rem;
        color: #bfbfed;
      }
      .enterbtnbox {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 0.12rem 0;
        .btn {
          width: 1.2rem;
          height: 0.26rem;
          border-radius: 0.02rem;
          border: 0.01rem solid #5a5a9a;
          text-align: center;
          line-height: 0.26rem;
          background: linear-gradient(135deg, #242448 0%, #42426d 100%);
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 0.16rem;
          color: #ffffff;
        }
      }
    }
  }
}
.one {
  background: url("../../assets/images/kaijiang/item1.png") no-repeat;
  background-size: 100% 100%;
}
.two {
  background: url("../../assets/images/kaijiang/item2.png") no-repeat;
  background-size: 100% 100%;
}
</style>
